<template>
<div class="main privacy container" id="main"><!-- Main Section-->
  <div class="row justify-content-center">
      <div class="col-md-10">
          <p>Version 1.1 April 27th, 2020</p>
          <h1>Terms of Service</h1>

          <p>This Terms of Service agreement define the legal agreement between you (“member”, “freelancer”,  “employer” “you”, “your”) and CitronWorks, Inc (“CitronWorks, “CitronWorkers”, “us”, “we”, “website”) on our domains (“citronworks.com”, “citronworkers.com”), hereafter collectively referred to as the “Terms of Service” or “ToS”.</p>

          <p>By visiting or using the services available on our website, you agree to be legally bound by these Terms of Service. We reserve the right to revise these Terms of Service from time to time in our sole discretion by updating this page with an updated revision date and number as the indication that the update has taken place. We also strive towards making our members aware of any changes to our Terms of Service by displaying pop-up messages when visiting our website. Unless otherwise provided in such revision, the revised terms will take effect on the date of the revision.</p>
          
          <p>If you do not agree to these Terms of Service, you may not visit, or use our services, and may not click to accept the Terms of Service when prompted on the website.</p>


          <h2>Identity (Know Your Customer)</h2>
          
          <p>You authorize us, directly or through third parties, to make any inquiries we consider necessary to validate your identity. </p>
          
          <p>You must, at our request:</p>

          <ul>
              <li>Provide further information to us, which may include asking for information and/or copies of documents that will allow us to reasonably identify you</li>
              <li>Take steps to confirm ownership of your email address or financial instruments</li>
              <li>Verify your information against third party databases or through other sources.
              </li>
          </ul>
          
          <p>We reserve the right to update your information on the website in order to match any identity documentation that has been provided.</p>
          
          <p>We reserve the right to close or suspend your account in the event we are unable to obtain or verify to our satisfaction the information which we request under this section.</p>

          <h2>Prohibited use of our website and services</h2>

          <p>You may not:</p>
          
          <ul>
            <li>misrepresent your experience, skills, or information, including by representing another person’s profile, or parts of another person’s profile, as your own</li>
            <li>falsely state or imply a relationship with another member or company</li>
            <li>allow another person to use your account, which is misleading to other members</li>
            <li>falsely state that one freelancer will do a job when another will in fact perform the work</li>
            <li>post identifying information concerning another person</li>
            <li>ask a freelancer to pay any fee for any job application</li>
            <li>request or demand free services, including requesting freelancers to submit work as part of the proposal process for very little or no money</li>
            <li>spam other members with proposals or invitations or posting the same job or freelancer profile multiple times so that more than one version remains active at a given time</li>
            <li>share or solicit contact information, such as email, phone number, or Skype ID, in a profile or job post</li>
            <li>use any form of robot, spider, scraper, or other automated means to access the website for any purpose</li>  
          </ul>

          <p>You may also not seek, offer, promote services, content, or activities that:</p>

          <ul>
            <li>are defamatory, illegal, profane, vulgar, threatening, unlawfully discriminatory, illegal, pornographic, obscene, or sexually explicit in nature</li>
            <li>would violate the intellectual property rights, such as and including copyrights, of another person, entity, service, product, or website</li>
            <li>would violate our Terms of Service or the terms of service of another company or website</li>
            <li>regard the creation, publication, distribution of “fake news”, “hoax news” or similar false content purposefully intended to mislead readers for financial or other gain</li>
            <li>regard or promote in any way any escort services, prostitution, or sexual acts</li>
            <li>are harassing toward another person based on the person's inclusion in a protected class as defined by applicable law</li>

          </ul>
          
          <h2>Limitation of Liability</h2>

          <p>In no event shall we, our affiliates or staff be liable, whether in contract, tort, or any other form of liability, even in the case of negligence, for:</p>

          <ol>
            <li>any indirect, incidental or consequential damages that may be incurred by you</li>
            <li>any loss of income, business or profits (direct or indirect) that may be incurred by you</li>
            <li>any claim, damage, or loss which may be incurred by you as a result of any of your transactions involving our services.</li>
          </ol>

          <p>The limitations on our liability to you above shall apply whether or not we, our related entities, our affiliates or staff have been advised of the possibility of such losses or damages arising.</p>
          <p>We expressly limit our liability for breach of a non-excludable condition or warranty implied by virtue of any legislation to the following remedies (the choice of which is to be at our discretion) to the supply of the freelancer services again or the payment of the cost of having the freelancer services supplied again.
          </p>

          <h2>Termination</h2>

          <h3>Voluntary termination</h3>

          <p>You may terminate your account at any time for any reason by going to the Billing section on our website. Your account will continue to be active until the last day of your current billing cycle, after which the termination will take effect. Upon termination, you will still have access to your posts, information, messages and attachments but you can no longer contact freelancers, see their contact information or post jobs.</p>

          <h3>Involuntary termination</h3>

          <p>Your account may be terminated or temporarily disabled if you do not pay your subscription fees in full, or if you breach any of the agreements in this Terms of Service.</p>

          <h2>Payments</h2>

          <p>You may pay by credit, debit card or PayPal. All prices on our website are shown in US Dollars and are inclusive of any taxes.</p>

          <p>Payments must be in full and we may refuse any order placed by you for any reason.</p>

          <p>All of our subscriptions are monthly or yearly and they will recur until they are cancelled by you and it is your sole responsibility to cancel your subscription before it is due.</p>

          <p>All of our subscriptions run for their full subscription period and may not be cancelled mid-term.</p>

          <h3>30-day money-back guarantee</h3>
          <p>You may request a full refund at any time during the first 30 days of your first subscription period with us. This 30-day money-back guarantee can only be invoked once.</p>

      </div>
  </div>  
</div>
</template>

<script>
  export default {
    metaInfo: {
      meta: [{
        name: 'title',
        content: 'Terms of Service',
      },
      {
        name: 'robots',
        content: 'noindex',
      }],
    }
  }
</script>

<style scoped>
  .privacy h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #454545;
  }
  .privacy h2 {
    font-size: 1.75rem;
    margin: 40px 0 40px 0;
    color: #E87722;
    font-weight: 600;
  }
  .privacy h3 {
    font-size: 1.5rem;
    margin: 40px 0 20px 0;
    padding: 8px 0;
    border-bottom: 1px solid #E87722;
    line-height: 1.6;
  }
  .privacy ul {
    list-style: circle;
    padding-left: 30px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  .privacy ol {
    padding-left: 30px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  .privacy li {
    margin-bottom: 5px;
    font-size: .9rem;
  }
  .privacy p {
    line-height: 1.6;
    margin-bottom: 20px;
  }
  .privacy {
    padding-top: 50px;
    padding-bottom: 50px;
  }
</style>